<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('STOCK.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyWarehousesIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <b-form-group :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
              <InputForm
                id="input-1"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                trim
                @input="clearServerError('name')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="select-group-1" :label="`${$t('STOCK.PARENT')}:`" label-cols-md="2" label-align-md="right" label-for="select-1">
              <SelectInfinityScroll
                ref="selectWarehouse"
                id="select-1"
                v-model="$v.form.outer.$model"
                :search.sync="search"
                api="stock/warehouses"
                :state="validateState('outer') === false"
                @input="clearServerError('outer')"
                clearable
              />
            </b-form-group>
            <b-form-group id="input-group-7" :label="`${$t('STOCK.DEFAULT')}`" label-for="check-1" label-cols-md="2" label-align-md="right">
              <span class="switch switch-icon">
                <label>
                  <input
                    id="check-1"
                    type="checkbox"
                    v-model="$v.form.default.$model"
                    value="true"
                  >
                  <span />
                </label>
              </span>
            </b-form-group>
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/warehousesRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../content/Card'
import InputForm from '../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../mixins/serverVuelidate'
import SelectInfinityScroll from '../../../../../components/forms-items/SelectInfinityScroll'

export default {
  name: 'CompanyBranchesEdit',
  components: {
    InputForm,
    KTCard,
    SelectInfinityScroll,
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      form: {
        name: '',
        outer: null,
        default: false,
      },
      validations: {
        form: {
          name: { required },
          outer: { },
          default: { },
        },
      },
      search: '',
    }
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('STOCK.TITLE_EDIT') }])
  },
  methods: {
    fetchData() {
      Repo.get(this.$route.params.id).then(({ data }) => {
        this.setItem(data.payload)
      })
    },
    normalizeTime(time) {
      if (!time) return ''
      return time.split(' ')[1].slice(0, 5)
    },
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true

      const obj = { ...this.form }
      obj.outer = obj.outer ? obj.outer.uuid : ''
      Repo.patch(this.$route.params.id, obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.EDITED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        setTimeout(() => this.$router.push({ name: 'companyWarehousesIndex' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
